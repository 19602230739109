import * as React from 'react';
import Box from '@mui/material/Box/Box.js';
import Stepper from '@mui/material/Stepper/Stepper.js';
import Step from '@mui/material/Step/Step.js';
import StepLabel from '@mui/material/StepLabel/StepLabel.js';
import StepContent from '@mui/material/StepContent/StepContent.js';
import Button from '@mui/material/Button/Button.js';
import Paper from '@mui/material/Paper/Paper.js';
import { Typography } from '@mui/material';
import VehicleFormProvider from './VehicleFormProvider.js';
import VehicleForm from './VehicleForm.js';
import VisitFormProvider from './VisitFormProvider.js';
import VisitForm from './VisitForm.js';
import StepperLandingStep from './StepperLandingStep.js';
import StepperGlobalTimeWindows from './StepperGlobalTimeWindows.js';
import StepperSubmitSimulation from './StepperSubmitSimulation.js';
export default function VerticalLinearStepper() {
    const [activeStep, setActiveStep] = React.useState(0);
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleReset = () => {
        setActiveStep(0);
    };
    const steps = [
        // Steps are responsible for validating data and allowing user to continue
        // TODO: Hide steps if the user chooses Curated List
        {
            label: 'Get started',
            description: ('Create routes for your visits or explore our demo routes. Routes use the latest weather data and Google Maps navigation.'),
            content: (React.createElement(StepperLandingStep, { handleNext: handleNext })),
        },
        {
            label: 'Choose global start date and end date',
            description: `Choose the start and end date of the simulation across all your planned visits.\n\n For example, if a storm starts on Jan 3rd at midnight, and ends on Jan 4th at noon your global start time would be 1/3 12:00 AM and global end time would be 1/4 12:00 PM`,
            content: (React.createElement(StepperGlobalTimeWindows, { handleNext: handleNext, handleBack: handleBack })),
        },
        {
            label: 'Choose vehicles',
            description: `Label each vehicle in your crew along with their working hours and the location of the depot where the vehicles will start and end.`,
            content: (React.createElement(VehicleFormProvider, null,
                React.createElement(VehicleForm, { handleNext: handleNext, handleBack: handleBack }))),
        },
        {
            label: 'Choose visits',
            description: `Select the addresses you're planning to visit along with the customer's given deadline as well as the expected duration and revenue of the visit.`,
            content: (React.createElement(VisitFormProvider, null,
                React.createElement(VisitForm, { handleNext: handleNext, handleBack: handleBack }))),
        },
        {
            label: 'Result',
            description: null,
            content: (React.createElement(StepperSubmitSimulation, { handleBack: handleBack })),
        },
    ];
    return (React.createElement(Box, { sx: { width: '100%' } },
        React.createElement(Stepper, { activeStep: activeStep, orientation: "vertical" }, steps.map((step, index) => (React.createElement(Step, { key: step.label },
            React.createElement(StepLabel, null, step.label),
            React.createElement(StepContent, null,
                React.createElement(Typography, null, step.description),
                React.createElement(Box, { sx: { mb: 2 } }, step.content)))))),
        activeStep === steps.length && (React.createElement(Paper, { square: true, elevation: 0, sx: { p: 3 } },
            React.createElement(Typography, null, "All steps completed - you're finished"),
            React.createElement(Button, { onClick: handleReset, sx: { mt: 1, mr: 1 } }, "Reset")))));
}
