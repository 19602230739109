import React, { createContext, memo, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import FirestoreListener from './FirestoreListener.js';
import { isUuidV4 } from '../constants/routes.js';
import { SIMULATION_STATUS } from '../constants/simulationStatus.js';
import { useAppDispatch, useAppSelector } from '../redux/hooks.js';
import { setSimulationId } from '../redux/simulationSlice.js';
export const RouteContext = createContext(undefined);
function RouteProvider({ children }) {
    // TODO: multiple re-renders on dev
    const location = useLocation().pathname;
    const parts = location.split('/');
    if (parts.length !== 3) {
        throw new Error(`Missing simulationId from URL ${location}`);
    }
    const simulationId = parts[2];
    if (!isUuidV4(simulationId)) {
        throw new Error(`Provided simulationId is not a valid UUID/bad format ${simulationId}`);
    }
    const dispatch = useAppDispatch();
    dispatch(setSimulationId(simulationId));
    const { result, status } = useAppSelector((state) => ({
        result: state.simulation.result,
        status: state.simulation.status,
    }));
    const renderContentForStatus = () => {
        // TODO: Render different component for errored out simulation
        switch (status) {
            case SIMULATION_STATUS.requestSuccessful:
                return React.createElement(React.Fragment, null, children);
            case SIMULATION_STATUS.requestFailed:
                return React.createElement(React.Fragment, null, "Route planning failed! Email sales@mmtplanning.com for assistance.");
            default:
                return React.createElement(React.Fragment, null, "Route is loading!");
        }
    };
    return (React.createElement(RouteContext.Provider, { value: result },
        React.createElement(FirestoreListener, { simulationId: simulationId, collectionId: 'results' }),
        renderContentForStatus()));
}
;
export function useSolutionContext() {
    return useContext(RouteContext);
}
const MemoizedRouteProvider = memo(RouteProvider);
export default MemoizedRouteProvider;
