/*eslint no-restricted-globals: ["error", "open"]*/
import React, { useEffect, useState } from 'react';
// MUI imports
import Grid from '@mui/material/Grid/Grid.js';
import Card from '@mui/material/Card/Card.js';
import CardActions from '@mui/material/CardActions/CardActions.js';
import CardContent from '@mui/material/CardContent/CardContent.js';
import Button from '@mui/material/Button/Button.js';
import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { CloseIcon, LoadingCircle, LockIcon } from '../constants/formIcons.js';
import { colorMappingForRoute } from '../constants/simulationStatus.js';
import { useSolutionContext } from './RouteProvider.js';
import LocationList from './LocationList.js';
import LocationListProvider from './LocationListProvider.js';
import { useAppDispatch, useAppSelector } from '../redux/hooks.js';
import { setEncodedPolylines, setEncodedPolylinesForTransitions, setPolylineHexColor, setLocationList } from '../redux/locationSlice.js';
import { RouteWeatherProvider } from './RouteWeatherProvider.js';
import { PAYMENT_STATUS } from '../constants/payments.js';
import PaymentModal from './PaymentModal.js';
import { handleTimeFormat } from '../constants/interpolationHelpers.js';
import { homepageURL } from '../constants/routes.js';
import { HomeIcon } from '../constants/formIcons.js';
function computeDuration(seconds) {
    const totalSeconds = parseInt(seconds.slice(0, -1), 10);
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    return { days, hours, minutes };
}
;
function handleDistanceUnit(distanceMeters, format) {
    return format === 'km' ? parseFloat((distanceMeters / 1000).toFixed(2)) : parseFloat((distanceMeters / 1609).toFixed(2));
}
let routeRevenues = {};
let routeDistances = {};
let encodedPolylines = {};
let encodedPolylinesForTransitions = {};
function populateRouteLocations(routes, distanceUnit, timeFormat) {
    let routeLocationList = {};
    let updatedRouteRevenues = {};
    if (!routes)
        return {};
    if (routes.length > Object.keys(colorMappingForRoute).length) {
        throw new Error(`Not enough colors to handle routes: limit ${Object.keys(colorMappingForRoute).length}`);
    }
    routes.forEach((route, routeIndex) => {
        if (route.type === 'IdleShipmentRoute')
            return;
        if (route.metrics.travelDistanceMeters) {
            routeDistances[routeIndex] = route.metrics.travelDistanceMeters;
        }
        ;
        if (route.routePolyline)
            encodedPolylines[routeIndex] = route.routePolyline;
        const visits = route.visits ? route.visits : [];
        const transitions = route.transitions ? route.transitions : [];
        const deploymentTransition = transitions[0];
        const deploymentTransitionRow = {
            distance: handleDistanceUnit(deploymentTransition.travelDistanceMeters ? deploymentTransition.travelDistanceMeters : 0, distanceUnit),
            distanceUnit: distanceUnit,
            duration: computeDuration(deploymentTransition.travelDuration),
            type: 'transition'
        };
        routeLocationList[routeIndex] = [deploymentTransitionRow];
        if (deploymentTransition.routePolyline) {
            encodedPolylinesForTransitions[routeIndex] = [deploymentTransition.routePolyline];
        }
        visits?.forEach((visit, visitIndex) => {
            const nextTransition = transitions[visitIndex + 1];
            if (!nextTransition.travelDuration) {
                throw new Error(`Missing next transition after visit is completed`);
            }
            const visitLabel = visit.visitLabel;
            if (!visitLabel) {
                throw new Error(`Solution output missing visitLabel for shipmentName ${visit.shipmentName}`);
            }
            const streetAddress = visitLabel.split('///')[0];
            const coordsStr = visitLabel.split('///')[1];
            const coords = {
                lat: parseFloat(coordsStr.split('_')[0]),
                lng: parseFloat(coordsStr.split('_')[1])
            };
            const revenueStr = visitLabel.split('///')[2];
            const revenue = revenueStr ? parseFloat(revenueStr) : 0;
            if (revenue) {
                if (revenue < 0) {
                    throw new Error(`Revenue for visit at ${streetAddress} is negative`);
                }
                if (updatedRouteRevenues[routeIndex]) {
                    updatedRouteRevenues[routeIndex] += revenue;
                }
                else {
                    updatedRouteRevenues[routeIndex] = revenue;
                }
            }
            const visitRow = {
                coords: coords,
                streetAddress: streetAddress,
                time: {
                    arrivalTime: visit.startTime,
                    departureTime: transitions[visitIndex + 1].startTime,
                },
                revenue: revenue,
                index: visitIndex + 1,
                routeId: routeIndex,
                type: 'visit',
            };
            routeLocationList[routeIndex].push(visitRow);
            const transitionRow = {
                distance: handleDistanceUnit(nextTransition.travelDistanceMeters ? nextTransition.travelDistanceMeters : 0, distanceUnit),
                distanceUnit: distanceUnit,
                duration: computeDuration(nextTransition.travelDuration),
                routeId: routeIndex,
                // index optionally left out unless a transition's index is necessary
                type: 'transition'
            };
            routeLocationList[routeIndex].push(transitionRow);
            if (nextTransition.routePolyline && encodedPolylinesForTransitions[routeIndex].length) {
                encodedPolylinesForTransitions[routeIndex].push(nextTransition.routePolyline);
            }
        });
    });
    routeRevenues = { ...updatedRouteRevenues };
    return routeLocationList;
}
;
function RouteModal({ expandedCardId, handleClose }) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, { raised: true, sx: { display: 'flex', position: 'fixed', height: '50vh', width: '100%', bottom: 0, left: 0 } },
            React.createElement(CardContent, { sx: {
                    flexGrow: 1,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    position: 'relative',
                    padding: 2,
                } },
                React.createElement(IconButton, { onClick: handleClose, sx: {
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        zIndex: 2,
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                        },
                    }, "aria-label": "close" },
                    React.createElement(CloseIcon, null)),
                React.createElement(LocationListProvider, null,
                    React.createElement(LocationList, null))))));
}
;
export default function RouteGrid() {
    const [isDesktop, setIsDesktop] = useState(true);
    useEffect(() => {
        const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
        const mobile = /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
        const tablet = /Tablet|iPad/i.test(userAgent);
        setIsDesktop(!mobile && !tablet);
    }, [isDesktop]);
    const dispatch = useAppDispatch();
    const solutionContext = useSolutionContext();
    const [expandedCardId, setExpandedCardId] = useState(null);
    const timeFormat = useAppSelector((state) => state.user.timeFormat);
    const distanceUnit = useAppSelector((state) => state.user.distanceUnit);
    const paymentStatus = useAppSelector((state) => state.simulation.paymentStatus);
    const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
    useEffect(() => {
        if (paymentStatus === PAYMENT_STATUS.COMPLETED) {
            setIsPaymentModalVisible(false);
        }
    }, [paymentStatus]);
    if (!solutionContext)
        return React.createElement(LoadingCircle, null);
    const context = { ...solutionContext };
    if (Object.keys(context).length === 0 || !context?.routes?.length) {
        return React.createElement(React.Fragment, null);
    }
    // TODO: Move to RouteProvider
    const lastRoute = context.routes[context.routes.length - 1];
    if (!lastRoute.vehicleStartTime) {
        console.error('Vehicle start time does not exist for the last route.');
    }
    const title = handleTimeFormat(lastRoute.vehicleStartTime, 'MMM DD');
    const activeRoutes = context.routes.filter(route => route.vehicleStartTime);
    const routeLocations = populateRouteLocations(activeRoutes, distanceUnit, timeFormat);
    const defaultRouteLocations = Object.values(routeLocations).flat();
    if (expandedCardId === null) {
        dispatch(setLocationList(defaultRouteLocations));
    }
    function isVisitListRow(row) {
        return row.type === 'visit';
    }
    const visitCoords = defaultRouteLocations
        .filter(isVisitListRow)
        .map(visit => visit.coords);
    function handleCardClick(routeIndex) {
        if (process.env.REACT_APP_SHOULD_RENDER_PAYMENT && paymentStatus !== PAYMENT_STATUS.COMPLETED) {
            setIsPaymentModalVisible(true);
            return;
        }
        dispatch(setLocationList(routeLocations[routeIndex]));
        if (Object.keys(encodedPolylines).length) {
            const routePolyline = encodedPolylines[routeIndex];
            const transitionsPolylines = encodedPolylinesForTransitions[routeIndex];
            dispatch(setPolylineHexColor(colorMappingForRoute[routeIndex + 1]));
            dispatch(setEncodedPolylines(routePolyline));
            dispatch(setEncodedPolylinesForTransitions(transitionsPolylines));
        }
        setExpandedCardId(expandedCardId === routeIndex ? null : routeIndex);
    }
    ;
    function handleClose() {
        setExpandedCardId(null);
        dispatch(setLocationList(defaultRouteLocations));
    }
    ;
    const navigateToHome = () => {
        window.location.href = homepageURL;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { sx: {
                display: 'flex',
                height: '36px',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '16px',
                position: 'relative',
            } },
            React.createElement(IconButton, { onClick: navigateToHome, sx: { padding: 0 } },
                React.createElement(HomeIcon, { sx: {
                        width: '34px',
                        height: '34px',
                        marginBottom: '16px'
                    } })),
            React.createElement(Typography, { variant: "h4", gutterBottom: true },
                "Routes on ",
                title)),
        React.createElement(Grid, { container: true, spacing: 3 }, activeRoutes.map((route, routeIndex) => (React.createElement(Grid, { item: true, key: routeIndex, xs: 12, sm: 6, md: 4 },
            React.createElement(Card, { variant: "outlined" },
                React.createElement(CardContent, null,
                    React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between' } },
                        React.createElement(Typography, { variant: "h6", component: "div" }, route.vehicleLabel ? route.vehicleLabel : `Vehicle #${routeIndex + 1}`),
                        process.env.REACT_APP_SHOULD_RENDER_PAYMENT && paymentStatus !== PAYMENT_STATUS.COMPLETED &&
                            React.createElement("div", { style: { width: 30, height: 30 }, onClick: () => setIsPaymentModalVisible(true) },
                                React.createElement(Tooltip, { title: "Unlock all route details" },
                                    React.createElement(LockIcon, null)))),
                    React.createElement(Divider, { sx: { my: 1 } }),
                    React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between' } },
                        React.createElement(Typography, { variant: "body1", color: "textSecondary" }, "Start:"),
                        React.createElement(Typography, { variant: "body1" }, handleTimeFormat(route.vehicleStartTime, timeFormat))),
                    React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', mt: 1 } },
                        React.createElement(Typography, { variant: "body1", color: "textSecondary" }, "End:"),
                        React.createElement(Typography, { variant: "body1" }, handleTimeFormat(route.vehicleEndTime, timeFormat))),
                    React.createElement(Divider, { sx: { my: 1 } }),
                    React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between' } },
                        React.createElement(Typography, { variant: "body1", color: "textSecondary" }, "Total Revenue:"),
                        React.createElement(Typography, { variant: "body1" }, routeRevenues[routeIndex] ? routeRevenues[routeIndex].toFixed(2) : 'N/A')),
                    React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', mt: 1 } },
                        React.createElement(Typography, { variant: "body1", color: "textSecondary" }, "Total Distance:"),
                        React.createElement(Typography, { variant: "body1" },
                            handleDistanceUnit(routeDistances[routeIndex], distanceUnit).toFixed(0),
                            ' ',
                            distanceUnit))),
                React.createElement(CardActions, null,
                    React.createElement(Button, { size: "small", onClick: () => handleCardClick(routeIndex) }, expandedCardId === routeIndex ? 'Close' : 'View Route'))))))),
        React.createElement(PaymentModal, { isOpen: isPaymentModalVisible, handleClose: () => setIsPaymentModalVisible(false) }),
        React.createElement(RouteWeatherProvider, { visitCoords: visitCoords }, expandedCardId !== null &&
            React.createElement(RouteModal, { expandedCardId: expandedCardId, handleClose: handleClose }))));
}
;
