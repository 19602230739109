import React, { useState, useCallback, useEffect, useRef } from "react";
import { TextField, IconButton, Button, Box, Stack, Typography, Card, CardContent, Divider, CardActions } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/AdapterDayjs.js';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider.js';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker/DateTimePicker.js';
import Grid from '@mui/material/Grid/Grid.js';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';
import { AddIcon, EditIcon, CheckIcon, DeleteIcon, ResetIcon } from '../constants/formIcons.js';
import { useFormContext } from "./VehicleFormProvider.js";
import { useAppSelector } from "../redux/hooks.js";
import { convertToISO8601 } from "../constants/interpolationHelpers.js";
dayjs.extend(utc);
dayjs.extend(timezone);
function FormRow({ row, idx, onError }) {
    // Flow:
    // 1. Load values from row props into state variables.
    // 2. update state variables with input.
    // 3. once isDone, then either update formContext.rows or update visits directly with updated shipment
    if (!row.value.label ||
        !row.value.startLocation ||
        !row.value.startTimeWindows ||
        !row.value.endTimeWindows) {
        throw new Error(`Vehicle missing label, start location or start/end time windows!`);
    }
    const formContext = useFormContext();
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeFormatType = useAppSelector((state) => state.user.timeFormat);
    // 1. Reading Row prop and updating state variables
    const globalStartTimeISO8601 = useAppSelector((state) => state.simulation.request?.optimizationRequest?.model.globalStartTime) || '';
    const globalEndTimeISO8601 = useAppSelector((state) => state.simulation.request?.optimizationRequest?.model.globalEndTime) || '';
    const globalStartTime = dayjs(globalStartTimeISO8601);
    const globalEndTime = dayjs(globalEndTimeISO8601);
    const savedVehicleStartTime = dayjs(row.value.startTimeWindows[0].startTime);
    const savedVehicleEndTime = dayjs(row.value.endTimeWindows[0].endTime);
    if (!globalStartTimeISO8601 || !globalEndTimeISO8601) {
        console.error(`Missing global start/end times in Vehicle row`);
    }
    function getStartLocationAddressFromProps() {
        try {
            const serializedLabel = row.value.label.split('///');
            const savedLocationAddress = serializedLabel[1];
            return savedLocationAddress ? savedLocationAddress : '';
        }
        catch (e) {
            throw new Error(`Was not able to parse origin address from label: ${row.value.label}`);
        }
        ;
    }
    ;
    const startLocationRef = useRef(null);
    const vehicleLabel = `Vehicle #${idx + 1}`;
    const [vehicleStartTime, setVehicleStartTime] = useState(savedVehicleStartTime);
    const [vehicleEndTime, setVehicleEndTime] = useState(savedVehicleEndTime);
    const [startLocationAddress, setStartLocationAddress] = useState(getStartLocationAddressFromProps());
    const [startLocationCoords, setStartLocationCoords] = useState(row.value.startLocation);
    const [isInvalidVehicleLocation, setIsInvalidVehicleLocation] = useState(false);
    const [isInvalidVehicleStartTime, setIsInvalidVehicleStartTime] = useState(false);
    const [isInvalidVehicleEndTime, setIsInvalidVehicleEndTime] = useState(false);
    // 2. Update state variables with user input
    const handleStartLocationAddress = useCallback((locationAddress) => {
        setStartLocationAddress(locationAddress);
        if (startLocationRef.current) {
            const getAutocompleteAddress = function (formattedAddress, locationCoords) {
                setStartLocationAddress(formattedAddress);
                setStartLocationCoords({
                    latitude: locationCoords.lat(),
                    longitude: locationCoords.lng(),
                });
            };
            formContext.additionalContext.fetchAddressSuggestions(startLocationRef, getAutocompleteAddress);
        }
    }, [formContext.additionalContext]);
    const resetVehicleStartTimes = useCallback(() => {
        setVehicleStartTime(globalStartTime);
        setVehicleEndTime(globalEndTime);
        setIsInvalidVehicleStartTime(false);
        setIsInvalidVehicleEndTime(false);
    }, [globalStartTime, globalEndTime]);
    // 3. Update Rows with changed row
    const handleRowDelete = useCallback(() => {
        formContext.deleteRow(row.id);
    }, [formContext, row.id]);
    const handleRowUpdate = useCallback(() => {
        if (!row.isDone) {
            if (!startLocationAddress || !startLocationCoords.latitude || !startLocationCoords.longitude) {
                setIsInvalidVehicleLocation(true);
                return;
                // throw new Error(`Missing valid location details from submission!`);
            }
            if (vehicleStartTime.isBefore(globalStartTime)) {
                setIsInvalidVehicleStartTime(true);
                return;
            }
            if (vehicleEndTime.isSame(vehicleStartTime) || vehicleEndTime.isAfter(globalEndTime)) {
                setIsInvalidVehicleEndTime(true);
                return;
            }
        }
        const startTimeWindow = {
            startTime: convertToISO8601(vehicleStartTime),
            endTime: convertToISO8601(vehicleEndTime),
        };
        const endTimeWindow = {
            startTime: convertToISO8601(vehicleStartTime),
            endTime: convertToISO8601(vehicleEndTime),
        };
        const serializedVehicleLabel = encodeURIComponent(vehicleLabel);
        const serializedLabel = `${serializedVehicleLabel}///${startLocationAddress}`;
        const updatedVehicle = {
            label: serializedLabel,
            startLocation: startLocationCoords,
            startTimeWindows: [startTimeWindow],
            endTimeWindows: [endTimeWindow],
        };
        const updatedRow = {
            id: row.id,
            value: updatedVehicle,
            isDone: !row.isDone,
        };
        formContext.updateRow(updatedRow);
    }, [formContext, row.id, row.isDone, globalStartTime, globalEndTime, startLocationAddress, startLocationCoords, vehicleLabel, vehicleStartTime, vehicleEndTime]);
    useEffect(() => {
        if (vehicleStartTime.isBefore(globalStartTime) ||
            vehicleStartTime.isSame(globalEndTime) ||
            vehicleStartTime.isAfter(globalEndTime)) {
            if (!row.isDone) {
                setVehicleStartTime(globalStartTime);
                setIsInvalidVehicleStartTime(false);
            }
            setIsInvalidVehicleStartTime(true);
        }
        if (vehicleEndTime.isBefore(globalStartTime) ||
            vehicleEndTime.isSame(globalStartTime) ||
            vehicleEndTime.isAfter(globalEndTime)) {
            if (!row.isDone) {
                setVehicleEndTime(globalEndTime);
                setIsInvalidVehicleEndTime(false);
            }
            setIsInvalidVehicleEndTime(true);
        }
        if (vehicleStartTime.isSame(vehicleEndTime)) {
            setIsInvalidVehicleEndTime(true);
        }
    }, [row.isDone, globalStartTime, globalEndTime, vehicleStartTime, vehicleEndTime]);
    return (React.createElement(Card, { key: row.id, variant: "outlined", sx: { mb: 2 } },
        React.createElement(CardContent, null, row.isDone ? (React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Typography, { variant: "h6" }, vehicleLabel)),
            React.createElement(Divider, { sx: { width: '100%', my: 1 } }),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', mb: 1 } },
                    React.createElement(Typography, { variant: "body2", color: "textSecondary" }, "Starts at:"),
                    React.createElement(Typography, { variant: "body2" }, vehicleStartTime?.tz(tz).format(timeFormatType))),
                React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', mb: 1 } },
                    React.createElement(Typography, { variant: "body2", color: "textSecondary" }, "Ends at:"),
                    React.createElement(Typography, { variant: "body2" }, vehicleEndTime?.tz(tz).format(timeFormatType))),
                React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', mb: 1 } },
                    React.createElement(Typography, { variant: "body2", color: "textSecondary", sx: { mr: 1 } }, "Location:"),
                    React.createElement(Typography, { variant: "body2" }, startLocationAddress)),
                (isInvalidVehicleStartTime || isInvalidVehicleEndTime) &&
                    React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', mb: 1 } },
                        React.createElement(Typography, { variant: "body2", color: 'red' },
                            "Vehicle start/end time should be within ",
                            globalStartTime.format(timeFormatType),
                            " and ",
                            globalEndTime.format(timeFormatType)))))) : (React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Typography, { variant: "h6" }, vehicleLabel)),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs },
                    React.createElement(Grid, { container: true, spacing: 1 },
                        React.createElement(Grid, { item: true, xs: 12, sm: 5.5 },
                            React.createElement(DateTimePicker, { slotProps: {
                                    textField: {
                                        error: isInvalidVehicleStartTime,
                                        helperText: isInvalidVehicleStartTime
                                            ? `Start time should occur after ${globalStartTime.format(timeFormatType)}`
                                            : null,
                                    },
                                }, label: "Choose start time for the vehicle", value: vehicleStartTime, onChange: (e) => {
                                    setVehicleStartTime(e);
                                    setIsInvalidVehicleStartTime(false);
                                }, minDateTime: globalStartTime, maxDateTime: vehicleEndTime })),
                        React.createElement(Grid, { item: true, xs: 10, sm: 5.5 },
                            React.createElement(DateTimePicker, { slotProps: {
                                    textField: {
                                        error: isInvalidVehicleEndTime,
                                        helperText: isInvalidVehicleEndTime
                                            ? `End time should occur between ${vehicleStartTime.format(timeFormatType)} ` +
                                                `and ${globalEndTime.format(timeFormatType)} `
                                            : null,
                                    },
                                }, label: "Choose end time for the job", value: vehicleEndTime, onChange: (e) => {
                                    setVehicleEndTime(e);
                                    setIsInvalidVehicleEndTime(false);
                                    onError(false);
                                }, minDateTime: vehicleStartTime, maxDateTime: globalEndTime })),
                        React.createElement(Grid, { item: true, xs: 2, sm: 1 },
                            React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" },
                                React.createElement(IconButton, { onClick: resetVehicleStartTimes },
                                    React.createElement(ResetIcon, null)),
                                React.createElement(Typography, { variant: "caption" }, "Reset")))))),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(TextField, { fullWidth: true, inputRef: startLocationRef, value: startLocationAddress, onChange: (e) => {
                        handleStartLocationAddress(e.target.value);
                        setIsInvalidVehicleLocation(false);
                    }, disabled: row.isDone, type: "text", placeholder: "Where does the vehicle start/end? (e.g. depot)", error: isInvalidVehicleLocation, helperText: isInvalidVehicleLocation
                        ? 'Missing vehicle start/return location'
                        : null }))))),
        React.createElement(Divider, null),
        React.createElement(CardActions, { sx: { justifyContent: 'flex-end' } },
            React.createElement(IconButton, { onClick: handleRowUpdate, color: row.isDone ? 'primary' : 'success' }, row.isDone ? React.createElement(EditIcon, null) : React.createElement(CheckIcon, null)),
            React.createElement(IconButton, { onClick: handleRowDelete },
                React.createElement(DeleteIcon, null)))));
}
;
export default function VehicleForm({ handleNext, handleBack }) {
    const formContext = useFormContext();
    const globalStartTime = dayjs(formContext.additionalContext.globalStartTimeISO8601);
    const globalEndTime = dayjs(formContext.additionalContext.globalEndTimeISO8601);
    const [showPendingRowsError, setShowPendingRowsError] = useState(false);
    const [incompleteFormError, setIncompleteFormError] = useState(false);
    const [invalidVehicleTimesError, setInvalidVehicleTimesError] = useState(false);
    const handleAddRow = () => {
        formContext.addRow();
        setIncompleteFormError(false);
    };
    useEffect(() => {
        const pendingRows = formContext.rows.filter(row => !row.isDone);
        if (pendingRows.length) {
            // TODO: user might be expecting error to disappear on updating FormRow,
            // not when they hit submit button.
            setShowPendingRowsError(true);
        }
        else {
            setShowPendingRowsError(false);
        }
    }, [formContext.rows]);
    useEffect(() => {
        let hasInvalidVehicleTimes = false;
        const completedRows = formContext.rows.filter(row => row.isDone);
        if (completedRows.length) {
            completedRows.forEach(row => {
                const vehicleStartTime = dayjs(row.value.startTimeWindows[0].startTime);
                const vehicleEndTime = dayjs(row.value.startTimeWindows[0].endTime);
                if (vehicleStartTime.isBefore(globalStartTime)) {
                    hasInvalidVehicleTimes = true;
                }
                if (vehicleEndTime.isSame(vehicleStartTime) || vehicleEndTime.isAfter(globalEndTime)) {
                    hasInvalidVehicleTimes = true;
                }
            });
        }
        setInvalidVehicleTimesError(hasInvalidVehicleTimes);
    }, [formContext.rows, globalStartTime, globalEndTime]);
    const handleError = useCallback((hasInvalidVehicleTimes) => {
        setInvalidVehicleTimesError(hasInvalidVehicleTimes);
    }, []);
    const handleSubmit = () => {
        // Check 1: check for incomplete rows
        // Check 2: check for unsubmitted rows
        if (showPendingRowsError) {
            return;
        }
        if (invalidVehicleTimesError) {
            return;
        }
        const vehicles = formContext.rows
            .filter(row => row.isDone)
            .map(row => row.value);
        if (!vehicles.length) {
            setIncompleteFormError(true);
            return;
        }
        formContext.additionalContext.setVehicles(vehicles);
        handleNext();
        console.log(JSON.stringify(vehicles, null, 2));
    };
    return (React.createElement("div", { className: "form", style: { width: '100%' } },
        React.createElement(Box, { sx: { width: '100%' } },
            React.createElement(Stack, { spacing: 2, sx: { width: '100%' } }, formContext.rows.map((row, idx) => (React.createElement(FormRow, { key: row.id, row: row, idx: idx, onError: handleError })))),
            showPendingRowsError ?
                React.createElement(Typography, { variant: "body1", color: 'red' }, "Finish pending vehicle \u2705 or remove it \u26D4\uFE0E") :
                null,
            incompleteFormError ?
                React.createElement(Typography, { variant: "body1", color: 'red' }, "Add a vehicle to plan a snow removal route") :
                null,
            React.createElement(Stack, { direction: "row", spacing: 2, sx: { mt: 2, justifyContent: 'center' } },
                React.createElement(Button, { onClick: handleAddRow },
                    React.createElement(AddIcon, { color: "primary" })),
                React.createElement(Button, { onClick: handleSubmit, color: "primary", variant: "contained" }, "Save"),
                React.createElement(Button, { onClick: handleBack, color: "secondary" }, "Back")))));
}
;
