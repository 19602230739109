import React from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@mui/material';
import DeviceDetect from './DeviceDetect.js';
import { useAppSelector } from '../redux/hooks.js';
import MemoizedPaymentOptions from './PaymentOptions.js';
import { CloseIcon } from '../constants/formIcons.js';
export default function PaymentModal({ handleClose, isOpen }) {
    const { isMobile, isTablet } = DeviceDetect();
    const isDesktop = !isMobile && !isTablet;
    const paymentTotal = useAppSelector((state) => state.simulation.paymentTotal);
    const routeImagePreviewURL = isDesktop ?
        'https://storage.googleapis.com/mmt-squarespace/assets/payment_modal_image_preview_desktop.png' :
        'https://storage.googleapis.com/mmt-squarespace/assets/payment_modal_image_preview_mobile.png';
    return (React.createElement(Dialog, { open: isOpen, onClose: handleClose, fullWidth: true, maxWidth: "sm", scroll: "paper" },
        React.createElement(DialogTitle, { sx: { position: 'relative', paddingRight: '48px' } },
            React.createElement(Typography, { variant: "h6" },
                "Unlock this route for only $",
                paymentTotal,
                "!"),
            React.createElement(IconButton, { "aria-label": "close", onClick: handleClose, sx: {
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                } },
                React.createElement(CloseIcon, null))),
        React.createElement(DialogContent, { dividers: true },
            React.createElement(Box, { mb: 2 },
                React.createElement(Typography, { variant: "body1", paragraph: true }, "Satisfy your customers with accurate ETAs that handle both weather and navigation!"),
                React.createElement(Typography, { variant: "body1", paragraph: true },
                    "For ",
                    React.createElement("strong", null,
                        "$",
                        paymentTotal),
                    ", prepare for every stop's",
                    React.createElement("br", null),
                    "\uD83D\uDD70\uFE0F ",
                    React.createElement("strong", null, "Arrival and departure times"),
                    React.createElement("br", null),
                    "\uD83C\uDF28\uFE0F ",
                    React.createElement("strong", null, "Expected snow depth upon arrival"))),
            React.createElement(Box, { sx: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 2,
                    backgroundColor: '#f9f9f9',
                    border: '1px solid #e0e0e0',
                    borderRadius: 2,
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                    marginBottom: 2,
                } },
                React.createElement("img", { src: routeImagePreviewURL, alt: "Route preview", style: { maxWidth: '100%', height: 'auto', borderRadius: '4px' } })),
            React.createElement(Typography, { variant: "body1", paragraph: true }, "Email sales@mmtplanning.com for info (response time: <20 min)."),
            React.createElement(Divider, { sx: { my: 2 } }),
            React.createElement(MemoizedPaymentOptions, null))));
}
