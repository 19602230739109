import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useMapsLibrary, useMap } from '@vis.gl/react-google-maps';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppSelector } from '../redux/hooks.js';
import { validateLocation } from '../resources/validateSiteEligibility.js';
import { setRequestVisits } from '../redux/simulationSlice.js';
;
const defaultContextValue = {
    rows: [],
    addRow: () => { },
    deleteRow: () => { },
    updateRow: () => { },
    additionalContext: {
        globalStartTimeISO8601: '',
        globalEndTimeISO8601: '',
        fetchAddressSuggestions: () => { },
        setVisits: () => { }
    }
};
export const FormContext = createContext(defaultContextValue);
export default function VisitFormProvider({ children }) {
    const map = useMap();
    const placesLib = useMapsLibrary('places');
    const [rows, setRowsState] = useState([]);
    const dispatch = useAppDispatch();
    // TODO: Use latest vehicle start and end times
    let globalStartTimeISO8601 = useAppSelector((state) => state.simulation.request?.optimizationRequest?.model.globalStartTime) || '';
    let globalEndTimeISO8601 = useAppSelector((state) => state.simulation.request?.optimizationRequest?.model.globalEndTime) || '';
    if (!globalStartTimeISO8601 || !globalEndTimeISO8601) {
        throw new Error('Missing global start and end times for visits');
    }
    let visits = useAppSelector((state) => state.simulation.request?.optimizationRequest?.model.shipments);
    const createEmptyShipment = useCallback(() => {
        const defaultTimeWindow = {
            startTime: globalStartTimeISO8601,
            endTime: globalEndTimeISO8601,
        };
        const emptyPickup = {
            label: '///'.repeat(2),
            arrivalLocation: {
                latitude: 0,
                longitude: 0,
            },
            timeWindows: [defaultTimeWindow],
            duration: '0s',
        };
        const emptyShipment = {
            costsPerVehicle: [],
            pickups: [emptyPickup],
        };
        return emptyShipment;
    }, [globalStartTimeISO8601, globalEndTimeISO8601]);
    const addRow = useCallback(() => {
        const newRow = { id: uuidv4(), value: createEmptyShipment(), isDone: false };
        setRowsState(prevRows => [...prevRows, newRow]);
    }, [createEmptyShipment]);
    const updateRow = useCallback((changedRow) => {
        setRowsState((prevRows) => prevRows.map(row => row.id === changedRow.id ? { ...changedRow } : row));
    }, []);
    const deleteRow = useCallback((deleteRowId) => {
        setRowsState((prevRows) => prevRows.filter(row => row.id !== deleteRowId));
    }, []);
    useEffect(() => {
        // Update rows with prev submitted visits from simulation request store
        if (!visits || !visits.length) {
            // Note: should initialize with empty row; issue is hook runs twice w/o updating state
            return;
        }
        const updatedRows = visits.map(visit => {
            if (!visit.pickups.length || !visit.costsPerVehicle.length) {
                throw new Error('Malformed visit: missing pickup details or vehicle costs');
            }
            // #199: Update visit start/end times to be compliant with visit start/end times
            // TODO: VisitForm should throw an error if vehicle start and end times require
            // correction because they are out of bounds.
            return {
                id: uuidv4(),
                value: visit,
                isDone: true
            };
        });
        setRowsState(updatedRows);
    }, [globalStartTimeISO8601, globalEndTimeISO8601, visits]);
    const setVisits = useCallback((visits) => {
        dispatch(setRequestVisits(visits));
    }, [dispatch]);
    const fetchAddressSuggestions = useCallback((inputRef, getAutocompleteAddress) => {
        if (!placesLib || !map || !inputRef.current)
            return;
        const autocompleteService = new placesLib.Autocomplete(inputRef.current, {
            bounds: map.getBounds(),
            fields: ["formatted_address", "geometry.viewport", "geometry.location"],
            types: ['geocode'],
        });
        const updateAutocompleteBounds = () => {
            const bounds = map.getBounds();
            autocompleteService.setBounds(bounds);
        };
        const onPlaceChanged = () => {
            const place = autocompleteService.getPlace();
            if (place.formatted_address && place.geometry?.viewport && place.geometry.location) {
                map.fitBounds(place.geometry.viewport);
                const isEligibleLocation = validateLocation(place.geometry.location);
                getAutocompleteAddress(place.formatted_address, place.geometry.location, isEligibleLocation);
            }
        };
        autocompleteService.addListener('place_changed', onPlaceChanged);
        map.addListener('bounds_changed', updateAutocompleteBounds);
        // Cleanup function to remove the listeners
        return () => {
            window.google.maps.event.clearInstanceListeners(autocompleteService);
            window.google.maps.event.clearInstanceListeners(map);
        };
    }, [map, placesLib]);
    const visitFormContextValue = {
        rows,
        addRow,
        deleteRow,
        updateRow,
        additionalContext: {
            globalStartTimeISO8601,
            globalEndTimeISO8601,
            fetchAddressSuggestions,
            setVisits
        }
    };
    return (React.createElement(FormContext.Provider, { value: visitFormContextValue }, children));
}
;
export function useFormContext() {
    return useContext(FormContext);
}
