import dayjs from "dayjs";
import { roundToNearestHours, interpolateSnowDepth, interpolateStartTime, convertToISO8601 } from "../constants/interpolationHelpers.js";
/*
Snowfall in the hour before deadline should be strictly below the threshold.

1. Filter for non-continuous results from the location store (request)
2. Find relevant overlapping weather event block, if d.n.e, then discount that visit from the list
3. For the relevant weather block, starting at the start time, do an aggregated snow depth at each hour.
4. At the aggregated total at the deadline, figure out which is the latest time where the agg. snow depth is (agg snow depth at deadline) - snow depth trigger. This is the start time, the end time is the deadline.

Edge cases that cannot be addressed:
e.g. deadline is 19:00, should be able to start 18:35 when expectedSnowfall = threshold
but then the earliest start time is 19:00, since at 18:00 you would be exceeding.
*/
export default function generateRequestTimeWindow(visit, weatherEvents, queryResults) {
    let timeWindow;
    try {
        if (visit.timeWindows.length === 0)
            throw new Error();
        timeWindow = visit.timeWindows[0];
    }
    catch (e) {
        throw new Error(`Failed to find valid time window in visit`);
    }
    const globalStartTime = dayjs(timeWindow.startTime);
    const deadline = dayjs(timeWindow.endTime);
    // Step 1: Cursory check to find a contiguous event range from global start to deadline
    const relevantWeatherEvent = weatherEvents.filter(weatherEvent => {
        //  start time <= WeatherEvent < end time
        // e.g. if the start time is 12:00, the first MMQueryResult in the time window was 12:00
        // e.g. if the end time is 19:00, the last MMQueryResult in the time window was 18:00
        const eventStartTime = dayjs(weatherEvent.startTime);
        const eventEndTime = dayjs(weatherEvent.endTime);
        return eventStartTime.isBefore(deadline) &&
            (eventEndTime.isAfter(deadline) || eventEndTime.isSame(deadline));
    });
    if (!relevantWeatherEvent.length) {
        // No snow above threshold at the time
        return false;
    }
    // TODO: #297 Investigate why there are duplicate weather events
    // if (relevantWeatherEvent.length > 1) {
    //     throw new Error(`More than one weather events found for the deadline! Events: ${JSON.stringify(relevantWeatherEvent)}`);
    // }
    // Step 2: Vet MMQueryResult times to:
    // A. get precise start times and
    // B. check if duration is sufficient
    const roundedGlobalStartTimes = roundToNearestHours(globalStartTime);
    const hourBeforeGlobalStartTime = roundedGlobalStartTimes.hourAfter;
    const roundedDeadlineTimes = roundToNearestHours(deadline);
    const hourAfterDeadline = roundedDeadlineTimes.hourAfter;
    const snowDepthTrigger = relevantWeatherEvent[0].snowDepthTrigger;
    const snowDepthAtTimes = {};
    queryResults.forEach((queryResult) => {
        const time_in_utc = queryResult.time_in_utc;
        const time = dayjs(time_in_utc);
        // Edge case 1: deadline: non-zero minutes and seconds: require hour after deadline 
        // Edge case 2: global start time: non-zero minutes and seconds: require hour before start time 
        if ((hourAfterDeadline.isAfter(time) || hourAfterDeadline.isSame(time)) &&
            (hourBeforeGlobalStartTime.isBefore(time) || hourBeforeGlobalStartTime.isSame(time))) {
            snowDepthAtTimes[time_in_utc] = queryResult.snow_depth_in_cm;
        }
    });
    // console.log(snowDepthAtTimes);
    function findEarliestStartTime(snowDepthAtTime, deadline, threshold) {
        // iterate from deadine to beginning -- find the earliest hour where total snowfall 
        // doesn't exceed snow depth threshold.
        // Assumption: snow depth is the value at the start of the 1h interval
        const times = Object.keys(snowDepthAtTime);
        if (!times.length) {
            throw new Error(`Missing snow depth data when computing earliest time`);
        }
        if (threshold === 0) {
            return times[0];
        }
        const snowDepth1 = snowDepthAtTime[convertToISO8601(roundedDeadlineTimes.hourBefore)];
        const snowDepth2 = snowDepthAtTime[convertToISO8601(roundedDeadlineTimes.hourAfter)];
        if (typeof (snowDepth1) === 'undefined' || typeof (snowDepth2) === 'undefined') {
            console.log(`Data was not provided for snow depths at deadlines either: ` +
                `${convertToISO8601(roundedDeadlineTimes.hourBefore)} or ${convertToISO8601(roundedDeadlineTimes.hourAfter)}`);
            throw new Error(`Data was not provided for snow depths at deadlines either: ` +
                `${convertToISO8601(roundedDeadlineTimes.hourBefore)} or ${convertToISO8601(roundedDeadlineTimes.hourAfter)}`);
        }
        let snowDepthAtDeadline = interpolateSnowDepth(roundedDeadlineTimes.hourBefore, snowDepth1, roundedDeadlineTimes.hourAfter, snowDepth2, deadline);
        if (!snowDepthAtDeadline) {
            snowDepthAtDeadline = snowDepthAtTime[convertToISO8601(deadline)];
        }
        let earliestStartTime = null;
        for (let i = times.length - 1; i >= 0; i--) {
            // times: floor(global start time) <= time[i] <= ceil(deadline)
            const time = times[i];
            if (dayjs(time).isSame(deadline)) {
                continue;
            }
            let startSnowDepth = snowDepthAtTime[time];
            const isTimeBeforeGlobalStart = dayjs(time).isBefore(globalStartTime);
            // Edge case: global start not at the hour e.g. global start time is 17:15
            if (isTimeBeforeGlobalStart) {
                const hourAfterStart = roundedGlobalStartTimes.hourAfter;
                const interpolatedEarliestTimeDepth = interpolateSnowDepth(dayjs(time), snowDepthAtTime[time], hourAfterStart, snowDepthAtTime[convertToISO8601(hourAfterStart)], globalStartTime);
                if (interpolatedEarliestTimeDepth) {
                    startSnowDepth = interpolatedEarliestTimeDepth;
                }
                ;
            }
            const expectedSnowfall = snowDepthAtDeadline - startSnowDepth;
            if (expectedSnowfall >= threshold) {
                // First instance where there is more snowfall than what the threshold is
                // allowing clearance.
                // Edge case: start time: find the precise moment where expected snowfall = threshold
                let preciseStartTime;
                if (isTimeBeforeGlobalStart) {
                    preciseStartTime = convertToISO8601(globalStartTime);
                }
                else {
                    const followingHour = dayjs(time).add(1, 'hour');
                    // e.g. if the s.d. trigger is 5cm and at deadline, the s.d. is 21.25 cm
                    // the difference threshold would be 21.25 - 5 = 16.25 cm
                    const differenceThreshold = snowDepthAtDeadline - threshold;
                    preciseStartTime = interpolateStartTime(dayjs(time), startSnowDepth, followingHour, snowDepthAtTime[convertToISO8601(followingHour)], differenceThreshold);
                }
                earliestStartTime = preciseStartTime;
                break;
            }
            if (expectedSnowfall < threshold) {
                earliestStartTime = time;
            }
        }
        return earliestStartTime;
    }
    // Note: Duration can safely be excluded: vehicles can arrive outside of window,
    // Fleet Routing API handles duration for us.
    const earliestStartTime = findEarliestStartTime(snowDepthAtTimes, deadline, snowDepthTrigger);
    if (!earliestStartTime) {
        console.error(`Insufficient time for snow removal at ` +
            `${visit.arrivalLocation.latitude},${visit.arrivalLocation.longitude} due to heavy weather`);
        return false;
    }
    return {
        ...timeWindow,
        startTime: earliestStartTime,
    };
}
